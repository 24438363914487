

























































































































import Base from '@/mixins/Base.vue';
import { IBlogPost, IBlogPostsResponse } from '@/interfaces/blogpost';
import { IPagination } from '@/interfaces/pagination';
import { IBlogCategory } from '@/interfaces/blogcategory';

const component = Base.extend({
    data() {
        return {
            params: {
                is_published: null,
                source: null,
                category: undefined as any | IBlogCategory,
                title: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                per_page: 12,

            },

            posts: {
                data: [] as Array<IBlogPost>,
                meta: {} as IPagination | any,
            } as IBlogPostsResponse,
        };
    },
    mounted() {
        this.getBlogPosts();
    },
    methods: {
        getBlogPosts(page: number = 1): void {
            this.params.page = page;

            this.get<IBlogPostsResponse>('blog/posts', {
                params: {
                    ...this.params,
                    category_id: this.params.category ? this.params.category.id : null,
                },
            }).then(({ data }) => {
                this.posts = data;
            });
        },
    },
});

export default component;
